.topo{
	border-bottom:4px solid $theme2;

	@include media-breakpoint-up (lg) {
		padding-bottom: 16px;
		background-color: #031494;
		position: relative;

		@include beforeAfter{
			content: '';
			position: absolute;
			height: 3px;
			background-color: #fff;
			width: 100%;
			left: 0px;
		}
		&:before{
			bottom: 0px;
		}
		&:after{
			bottom: 8px;
		}
	}

	.nav-content{

		@include media-breakpoint-down(md){
			top:0;
			position:fixed;
			left:-230px;
			width:230px;
			height:100%;
			border-right:1px solid #000;
			transition:left 0.6s linear;
			overflow:auto;
			z-index:1000;
			background-color:#031494;
			display:flex;
			flex-wrap:wrap;
			flex-direction:row;

			.bar-secundaria{
				background-color: #ff0615;
			}
		}

		@include media-breakpoint-up(lg){

			.bar-secundaria{
				background-color:#ff0615;
				color:#FFF;
				position: relative;

				@include beforeAfter{
					content:'';
					display:block;
					position:absolute;
					top:0;
					width:100vw;
					height:100%;
					background-color:inherit;
				}

				&:before{
					left:100%;
				}

				&:after{
					right:100%;
				}
			}

			@include make-container();
			@include make-container-max-widths();
		}

		.newsletter-form{
			background-color:#ff0615;
			color:#FFF;
			position:relative;
			padding-top:11px;
			padding-bottom:11px;

			@include media-breakpoint-down(md){
				order:3;
				text-align:center;
			}

			@include media-breakpoint-up(lg){
				display:flex;
				flex-wrap:wrap;
				align-items:center;
				justify-content:center;

				@include beforeAfter{
					content:'';
					display:block;
					position:absolute;
					top:0;
					width:100vw;
					height:100%;
					background-color:inherit;
				}

				&:before{
					left:100%;
				}

				&:after{
					right:100%;
				}

			}

		}

		& > *{
			width:100%;
		}

		.bar-principal{
			background-color:#031494;
			color:#FFF;
			position:relative;
			padding-top:6px;
			padding-bottom:7px;

			@include media-breakpoint-down(md){
				order:2;
				text-align:center;
			}

			@include media-breakpoint-up(lg){
				display:flex;
				flex-wrap:wrap;
				align-items:center;
				justify-content:space-between;

				@include beforeAfter{
					content:'';
					display:block;
					position:absolute;
					top:0;
					width:100vw;
					height:100%;
					background-color:inherit;
				}

				&:before{
					left:100%;
				}

				&:after{
					right:100%;
				}

			}

			.info-left{
				padding-top:4px;
				padding-bottom:4px;

				a{

					&:hover{
						opacity:0.5;
					}

				}

				@include media-breakpoint-up(lg){
					display:flex;
				}

				.message-area{
					position:relative;
					font-size:18px;
					line-height:1;
					padding-top:12px;
					transition:opacity 0.6s linear;

					&:hover{
						opacity:0.5;
					}

					@include media-breakpoint-up(lg){
						padding-top:0;

						&:before{
							content:'';
							width:1px;
							height:20px;
							background-color:#FFF;
							opacity:0.6;
							display:inline-block;
							margin:0 12px;
							vertical-align:bottom;
						}

					}

				}

			}

			.info-right{
				padding-top:10px;

				.btn--theme-2{
					background-color: #ff0615;
					color: #fff;
					border-radius: 10px;

					&:hover{
						background-color: #fff;
						color: #ff0615;
					}
				}

				@include media-breakpoint-up(lg){
					display:flex;
					flex-wrap:wrap;
					align-items:center;
					padding-top:0;
				}

				.media-custom{
					align-items:center;
					line-height:1;
					font-size:14px;

					.media-custom__img{

						span{
							position:relative;
							top:-2px;
						}

					}

					.media-custom__text{

						span{
							font-family: 'montserratbold';
						}

					}

				}

				.form-buscas-topo{

					@include media-breakpoint-down(md){
						padding:15px 10px;
					}

				}

				.media-custom{

					.media-custom__text{

						@include media-breakpoint-down(md){
							padding-top:6px;
						}

					}

				}

			}

		}

		.bar-secundaria{

			@include media-breakpoint-down(md){
				order:1;
			}

		}

	}

}
.icone__nav{
	display: inline-flex;
	width: 20px;
	height: 20px;
	background-color: #fff;
	border-radius: 50%;
	align-items:center;
	justify-content: center;
	margin-right: 5px;
	font-size: 14px;
	line-height: 14px;
	color: #031494;

	&:hover{
		background-color: #ff0615;
		color: #fff;
		text-decoration: none;
		opacity: 1 !important;
	}
}
