.carousel{

	.carousel-item{
		margin-bottom:0;
	}

	.carousel-indicators{
		@include make-container();
		@include make-container-max-widths();
		margin-bottom:10px;
		text-align:left;
		justify-content:center;

		@include media-breakpoint-down(md){
			margin-bottom:1px;
		}

		& > *{
			font-size:18px;
			margin:0;
			width:1em;
			height:1em;
			line-height:1em;
			border-radius:100%;
			@include margin(0,4);
			display:block;
			opacity:1;
			transition:background-color 0.6s linear;

			&.active{
				background-color:#3d57c1;
			}

			&:last-child{
				margin-bottom:0;
			}

		}

	}

}