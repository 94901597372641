.section__box{
	padding-top:50px;
	max-width:852px;
	margin-left:auto;
	margin-right:auto;
	background-color:$theme2;
}

.destaques{
	padding-top:44px;
	padding-bottom:42px;
	position:relative;
	background-repeat:no-repeat;
	background-position:right bottom;

	.section__box{
		position:relative;

		&:before{
			content:"";
			display:block;
			width:851px;
			height:385px;
			background-image:url("../images/bg-sobre-header.jpg");
			position:absolute;
			top:0;
			left:0;
		}

		.header-section{
			position:relative;
			z-index:40;
		}

	}

}

.promocoes,
.setores,
.videos,
.lojas{

	@include media-breakpoint-down(md){
		text-align:center;

		.header-section{

			.header-section__title{

				&:after{
					margin-right:auto;
					margin-left:auto;
				}

			}

		}

	}

}

.promocoes{
	background-repeat:no-repeat;
	background-position:left bottom;
	padding-bottom:30px;

	.promocoes__figure{

		@include media-breakpoint-up(lg){
			position:relative;

			&:before{
				content:"";
				display:block;
				position:absolute;
				background-color:$theme;
				top:50%;
				left:calc(100% - 30vw);
				width:100vw;
				height:408px;
				transform:translateY(-50%);
			}

		}

	}

}

.setores{
	position:relative;
	padding-top:10px;
	padding-bottom:35px;

	@include media-breakpoint-up(lg){

		&:before{
			content:'';
			display:block;
			width:50vw;
			height:100%;
			background-color:#031494;
			position:absolute;
			top:0;
			left:0;
		}

	}

	.container{
		position:relative;
		z-index:40;
	}

}

.videos{
	padding-top:55px;
	padding-bottom:55px;

	.video-incrivel{
		background-repeat:no-repeat;
		background-size:cover;
		background-position:center center;
		min-height:326px;
		@extend .embed-responsive;
		max-width:600px;
		margin-left:auto;
		overflow:visible;

		@include media-breakpoint-down(md){
			margin-right:auto;
		}

		&:before{
			padding-top:(326 / 1920) * 100%;
		}

		&:after{
			content:'';
			display:block;
			position:absolute;
			left:-20px;
			top:-15px;
			width:316px;
			height:calc(100% + 32px);
			background-color:$theme2;
			z-index:-2;
		}

		.video-incrivel__controls{
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			display:flex;
			align-items:center;
			justify-content:center;

			.video-incrivel__controls-box{
				font-size:23px;
				width:(58 / 23) * 1em;
				height:(58 / 23) * 1em;
				line-height:(58 / 23) * 1em;
				display:flex;
				align-items:center;
				flex-wrap:wrap;
				justify-content:center;
				z-index:600;
				position:relative;
				background-color:#FFF;
				border-radius:100%;
				position:relative;

				.icon{
					position:relative;
					z-index:500;
				}

				@include beforeAfter{
					content:'';
					display:block;
					position:absolute;
					top:0;
					left:0;
					width:100%;
					height:100%;
					border-radius:inherit;
				}

				&:before{
					background-color:rgba(#FFF,0.5);
					transform:scale(1.4);
				}

				&:after{
					background-color:rgba(#FFF,0.5);
					transform:scale(1.8);
				}

			}

		}

	}

}

.lojas{
	background-color:$theme;
	padding-top:55px;
	padding-bottom:10px;
}
