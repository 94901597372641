.article-mapas{
	border-bottom:1px solid #e4e4e4;
	margin-bottom:40px;

	@include media-breakpoint-down(md){
		text-align:center;
	}

	.row{
		align-items:center;
	}

	.row > *{
		margin-bottom:40px;
	}

	.article-mapas__figure{
		position:relative;
		max-width:344px;
		margin-left:auto;
		margin-right:auto;
		border-radius: 20px;
		overflow: hidden;

		.article-mapas__absolute{
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			display:flex;
			flex-wrap:wrap;
			align-items:center;
			justify-content:center;
			background-color:rgba(darken(#1c4489,3%),0.8);
			font-size:15px;
			font-family: 'montserratextrabold';
			color:#FFF;
			transition:opacity 0.6s linear;
			opacity:0;

			@include media-breakpoint-down(md){
				opacity:1;
				background-color:rgba(darken(#1c4489,3%),0.6);
			}

			.article-mapas__absolute-text{

			}

		}

	}

	.article-mapas__title{
		font-size:20px;
		font-family: 'montserratbold';
		color:$theme2;
		margin-bottom:10px;
	}

	.article-mapas__desc{
		margin-bottom:14px;
		position:relative;

		&:after{
			content:"";
			border-radius:100%;
			margin-top:12px;
			margin-bottom:12px;
			display:block;
			width:8px;
			height:8px;
			background-color:$theme;

			@include media-breakpoint-down(md){
				margin-left:auto;
				margin-right:auto;
			}

		}

	}

	.article-mapas__footer{
		font-family: 'montserratbold';

		a{

			& + a{

				&:before{
					content:"|";
					display:inline-block;
					margin:0 4px;
					font-weight:bold;
				}

			}

		}

	}

	&:hover{

		.article-mapas__figure{

			.article-mapas__absolute{
				opacity:1;
			}

		}

	}

}
.internas-ocristal__segura-carousel{
	img{
		border-radius: 20px;
	}
}
.sac__titulo{
	font-size: 24px !important;
	line-height: 28px;
}
