// bg-position(x,y) width height
$icons: (
	'icon-casa': -46px 0px 33px 33px,
	'icon-clip': 0px -129px 17px 18px,
	'icon-cristal': 0px -43px 33px 33px,
	'icon-encarte': -43px -43px 33px 33px,
	'icon-facebook-novo': -132px -102px 7px 14px,
	'icon-headphone': -89px 0px 33px 33px,
	'icon-impressora': 0px 0px 36px 33px,
	'icon-instagram-novo': -132px -31px 15px 15px,
	'icon-loja': -89px -43px 33px 33px,
	'icon-message-novo': -54px -129px 16px 16px,
	'icon-phone': -132px -56px 13px 13px,
	'icon-play': -132px 0px 15px 21px,
	'icon-sac': 0px -86px 33px 33px,
	'icon-search': -132px -79px 13px 13px,
	'icon-setores': -43px -86px 33px 33px,
	'icon-share': -86px -86px 29px 32px,
	'icon-telegram-novo': -27px -129px 17px 17px,
	'icon-whatsapp-novo': -80px -129px 16px 16px,
);

@if $nativeCssIconVarialables {
	:root{
		@each $icon,$value in $icons {
			/*icon-#{$icon}*/
			--icon-#{$icon}-width: #{nth($value, 3)};
			--icon-#{$icon}-height: #{nth($value, 4)};
			--icon-#{$icon}-bg: #{nth($value, 1)} #{nth($value, 2)};
		}
	}
}

.icon{
	background-image: url('../images/sprites.png');
	background-repeat:no-repeat;
	background-size: 147px 147px;

	display: inline-block;
	vertical-align: middle;

}

.icon__mask{
	mask-image: url('../images/sprites.png');
	mask-repeat:no-repeat;
	mask-size: 147px 147px;

	display: inline-block;
	vertical-align: middle;

}

@each $icon,$value in $icons {
	.#{$icon} {
		width: nth($value,3);
		height: nth($value,4);
		$sprite-position: #{nth($value,1)} #{nth($value,2)};

		&.icon{
			background-position: $sprite-position;
		}

		&.icon__mask{
			mask-position: $sprite-position;
		}
	}
}
