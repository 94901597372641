@import "_fontfaces";

// FONTS USADAS
.font--mont-ex-bld{
	font-family: 'montserratextrabold';
}

.font--mont-bld{
	font-family: 'montserratbold';
}

.font--mont-reg{
	font-family: 'montserratregular';
}

.font--mont-med{
	font-family: 'montserratmedium';
}

.ativar-newsletter-form-topo{
	cursor:pointer;
	font-size:16px;
}

body{
	font-family: 'montserratregular';
	color:#616161;
	font-size:13px;
}

.modal#modal-principal{

	.modal-content{
		position:relative;

		.btn{
			position:absolute;
			top:-33px;
			right:0;
			font-family: 'montserratextrabold';
			z-index:1001;
			font-size:24px;
			display:flex;
			align-items:center;
			justify-content:center;
			width:(66 / 24) * 1em;
			height:(66 / 24) * 1em;
			border-radius:100%;
			font-weight:bold;
		}

	}

}

.font--fnt-13{
	font-size:13px;
}

.font--fnt-18{
	font-size:18px;
}

.text--theme{
	color:$theme !important;
}

.text--theme-2{
	color:$theme2 !important;
}

.segura-carousel{
	position:relative;
	padding-left:7px;
	padding-right:7px;

	.slick-slider{
		z-index:40;
	}

	.owl-nav{
		display:flex;
		flex-wrap:wrap;
		justify-content:space-between;
		align-items:center;
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		padding-bottom:28px; // metade do tamanho das flechas controladores de carousel

		& > *{
			z-index:100;
			position:relative !important;
			font-size:16px !important;
			color:#FFF !important;
			background-color:$theme !important;
			display:block !important;
			transition:all 0.6s linear;
			width:(29 / 16) * 1em !important;
			height:(56 / 16) * 1em !important;
			line-height:(56 / 16) * 1em !important;
			text-align:center;

			&.owl-prev{
				padding-left:12px;
				border-radius:30px 0 0 30px;
				left:-29px;
			}

			&.owl-next{
				padding-right:12px;
				border-radius:0 30px 30px 0;
				right:-29px;
			}

			@include hover-focus{
				background-color:$theme2 !important;
			}

		}

	}

	&.setores__segura-carousel{
		padding-right:18px;
		padding-left:18px;
	}

	&.destaques__segura-carousel{

		.owl-nav{

			& > *{

				&.owl-prev{
					left:-18px;
				}
	
				&.owl-next{
					right:-18px;
				}

			}

		}

	}

	&.internas-ocristal__segura-carousel{
		padding-left:0;
		padding-right:0;

		.owl-nav{

			& > *{

				&.owl-prev{
					left:1px;
				}

				&.owl-next{
					right:1px;
				}

			}

		}

	}

	&.segura-carousel--diferente{
		padding-left:0;
		
		@include media-breakpoint-up(lg){
			padding-right:60px;
			padding-left:60px !important;
		}

		.owl-carousel{
			position:static;

			@include media-breakpoint-down(md){
				margin-bottom:20px;
			}

		}

		.owl-nav{

			& > *{
				color:#b2b2b2 !important;
				font-size:38px !important;
				background-color:transparent !important;
				
				@include hover-focus{
					color:$theme2 !important;
				}

			}

		}

		.owl-carousel{

			.owl-dots{
				list-style-type:none;
				margin-bottom:0;
				padding-left:0;
				position:relative;
				padding-top:40px;
				counter-reset: dots;

				@include media-breakpoint-down(md){
					text-align:center;
				}
				
				@include media-breakpoint-up(lg){
					padding-top:0;
					position:absolute;
					bottom:13px;
					right:0;
					z-index:600;
					display:flex;
					flex-direction:column;
				}

				&:before{
					content:'';
					display:block;
					width:25px;
					height:7px;
					background-image:url('../images/bg-dots-slick-slider.png');
					background-repeat:no-repeat;
					position:absolute;
					top:calc(100% + 6px);
					left:50%;
					transform:translateX(-50%);
				}

				& > *{
					margin-bottom:6px;
					position:relative;

					@include media-breakpoint-down(md){
						display:block;
						margin-left:auto;
						margin-right:auto;
					}

					&:before{
						content:counter(dots);
						counter-increment:dots;
						display:block;
						position:absolute;
						top:50%;
						color:#FFF;
						font-family:'montserratbold';
						font-size:14px;
						left:50%;
						transform:translate(-50%,-50%);
					}

					&:last-child{
						margin-bottom:0;
					}

					& > *{
						font-size:14px;
						display:block;
						border:none;
						font-family: 'montserratbold';
						width:(30 / 14) * 1em;
						height:(30 / 14) * 1em;
						line-height:(30 / 14) * 1em;
						padding:0;
						border-radius:100%;
						text-align:center;
						background-color:$theme2;
						color:#FFF;
						transition:all 0.6s linear;

						@include hover-focus{
							background-color:$theme;
							color:#FFF;
						}

					}

					&.slick-active{
						
						& > *{
							background-color:$theme;
							color:#FFF;
						}

					}

				}

			}

		}

	}

	&.setores__segura-carousel{

		.owl-nav{
			padding-bottom: 28px + 24px;

		}

	}

}

.owl-carousel{

	.owl-stage-outer{
		z-index:1;
	}

	.owl-track{

		@include media-breakpoint-up(sm){
			display:flex;
			align-items:flex-end;
		}

	}

}

.embed-responsive-1920-114{
	min-height:114px;

	.embed-responsive__absolute{
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		display:flex;
		flex-wrap:wrap;
		align-items:center;
		justify-content:center;
		
		.btn{
			z-index:60;
			position:relative;
			box-shadow:0 0 4px rgba(#000,0.5);
		}

	}

	iframe{
		z-index:50;
	}

	&:before{
		padding-top:(114 / 1920) * 100%;
	}

}

.slick-slider{

	.slick-track{
		display:flex;
		flex-wrap:wrap;
		align-items:flex-end;
	}

}

[data-desligador="true"]{
	display:none;
	position:fixed;
	cursor:pointer;
	top:0;
	left:0;
	width:100%;
	height:100%;
	background-color:rgba(#000,0.5);
	z-index:1000;
}

#app{
	position:relative;
	overflow:hidden;
}

.topo a,
.rodape a{
	word-break:break-all;
}

.logo{
	margin-bottom:0;
}

.internas img{
	@include img-fluid();
}

a,
.btn{
	transition:all 0.6s linear;
}

a{
	color:inherit;
	text-decoration:none;

	@include hover-focus{
		color:inherit;
		text-decoration:none;
	}

}

*[data-animate]{
	opacity:0;
}

.animated{
	animation-direction:1.4s;
	animation-fill-mode: both;
}

.animated[data-animate]{
	opacity:1;
}

.atraso-1{
	animation-delay:0;
}

.atraso-2{
	animation-delay:0.2s;
}

.atraso-3{
	animation-delay:0.4s;
}

.atraso-4{
	animation-delay:0.6s;
}

lazy-image{
	background-color:transparent;
}

:focus{
	outline:none !important;
}

.font--italic{
	font-style:italic;
}

.text--theme{
	color:$theme;
}

.text--theme-2{
	color:$theme2;
}

.pl-65-lg{

	@include media-breakpoint-up(lg){
		padding-left:65px;
	}

}

.pr-65-lg{

	@include media-breakpoint-up(lg){
		padding-right:65px;
	}

}

// concerta erro meu (João) de sprites em outros projetos
.icon{

	@each $icon,$value in $icons {
		&.#{$icon} {
			width: nth($value,3);
			height: nth($value,4);
			background-position: #{nth($value,1)} #{nth($value,2)};
		}
	}
}

body{

	&.menu-active{

		.btn--responsivo{
			transform:rotate(45deg);

			.bar{

				@include beforeAfter{
					transform:rotate(-90deg);
				}

				&:before{
					top:0;
				}

				&:after{
					bottom:0;
				}

			}

		}

		.nav-content{
			left:0;
		}

	}

}