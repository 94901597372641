.card-setores{
	margin-bottom:48px;
	max-width:358px;
	margin-left:auto;
	margin-right:auto;
	transition:transform 0.6s linear;

	.card-setores__figure{
		position:relative;
		margin-bottom:0;

		.card-setores__tag,
		.card-setores__absolute{
			
			@include media-breakpoint-up(lg){
				position:absolute;
			}

		}

		.card-setores__tag{
			background-color:$theme2;
			bottom:0;
			color:#FFF;
			font-size:16px;
			font-family: 'montserratextrabold';
			min-width:182px;
			padding:7px 16px;
			text-align:left;
			left:12px;
			transition:background-color 0.6s linear;
		}

		.card-setores__absolute{
			left:0;
			bottom:0;
			width:100%;
			height:100%;
			color:#FFF;
			border:1px solid #FFF;
			display:flex;
			flex-wrap:wrap;
			align-items:center;
			justify-content:center;
			text-align:center;
			background-color:rgba(#000,0.4);
			padding:20px 30px;

			@include media-breakpoint-up(lg){
				padding:80px 60px;
				background-color:rgba(#000,0.8);
				opacity:0;
				transition:opacity 0.6s linear;
			}

			.card-setores__box{
				font-size:14px;

				&:before{
					content:"";
					display:inline-block;
					width:2px;
					background-color:#FFF;
					height:52px;
					margin-bottom:10px;
				}

				.card-setores__desc{
					line-height:1.15;
					margin-bottom:14px;
					max-height:160px;
					overflow:hidden;
					font-family:'montserratmedium';
				}

				.card-setores__plus{
					font-size:13px;
					display:inline-flex;
					flex-wrap:wrap;
					align-items:center;
					justify-content:center;
					width:(48 / 13) * 1em;
					height:(48 / 13) * 1em;
					background-color:#FFF;
					color:lighten($theme2,20%);
					border-radius:100%;
					font-weight:bold;
				}

			}

		}

	}

	&:hover{

		.card-setores__figure{

			.card-setores__absolute{
				opacity:1;
			}

			.card-setores__tag{
				background-color:$theme;
			}

		}

	}

}

.segura-carousel{

	.owl-carousel{

		.owl-stage{

			& > *.owl-item.center{
				
				.card-setores{

					&:hover{
						transform:scale(1.02) translateY(14px);
						z-index:200;
						position:relative;
					}

				}

			}

		}

	}

}

.internas-setores{

	.card-setores{

		&:hover{
			transform:scale(1.02) translateY(14px);
			z-index:200;
			position:relative;
		}

	}
	
}