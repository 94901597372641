.paginacao{
	display: flex;
	flex-wrap: wrap;

	.page__item{
		font-size:14px;
		font-family: 'montserratbold';
		width:(30 / 14) * 1em;
		height:(30 / 14) * 1em;
		line-height:(30 / 14) * 1em;
		padding:0;
		border-radius:100%;
		text-align:center;
		background-color:$theme2;
		color:#FFF;
		@include margin(0,1);

		@include hover-focus{
			background: #e4e4e4;
			color: $theme2;
		}

		.fa-chevron-left,
		.fa-chevron-right{
			font-size:20px;
			vertical-align:sub;
			line-height:1;
			transform:translateY(-1px);
		}

		.fa-chevron-left{
			@extend .fa-caret-left;
		}

		.fa-chevron-right{
			@extend .fa-caret-right;
		}

	}

	&.paginacao__start{
		justify-content: flex-start;
	}

	&.paginacao__center{
		justify-content: center;
	}

	&.paginacao__end{
		justify-content: flex-end;
	}

	&.paginacao__between{
		justify-content: space-between;
	}

	.page__item--active{
		background: #e4e4e4;
		color: $theme2;
	}
}
