.redes-sociais{
	display:inline-flex;
	align-items:flex-end;

	& > *{
		@include margin(0,5);
		font-size:15px;

		@include hover-focus{
			opacity:0.6;
		}

	}

}

.info-left__redes-sociais{

	& > *{
		font-size:18px;
	}

}