@import "_home.scss";

.internas{
	padding-bottom:52px;

	.article-setores{
		max-width:480px;
		margin-left:auto;
		margin-right:auto;

		.article-setores__figure{
			margin-bottom:0;
		}

	}

	.internas-breadcrumb{
		background-color:#031494;
		color:#FFF;
		padding-top:14px;
		padding-bottom:14px;
		text-align:center;
		position:relative;
		margin-bottom:48px;

		.internas-breadcrumb__title{
			font-size:15px;
			font-family: 'montserratbold';
			margin-bottom:0;

			@include media-breakpoint-up(lg){
				display:flex;
				flex-wrap:wrap;
				justify-content:center;
				align-items:center;
			}

			img{

				@include media-breakpoint-up(md){
					margin-right:6px;
				}

			}

		}

		@include beforeAfter{
			content:"";
			display:block;
			position:absolute;
			top:0;
			width:100vw;
			height:100%;
			background-color:inherit;
		}

		&:before{
			left:100%;
		}

		&:after{
			right:100%;
		}

		.icon{
			margin-right:4px;
			vertical-align:bottom;
		}

		.detalhes{
			display:inline-block;
			position:relative;

			&:before{
				content:'|';
				display:inline-block;
				margin:0 4px;
			}

		}

	}

	&.internas-ocristal{
		padding-bottom:0;
	}

	.hr-divider{
		border-top:5px solid #ededed;
		margin-bottom:24px;
	}

}
