.card-setores-internas{
	margin-bottom:40px;

	@include media-breakpoint-down(md){
		text-align:center;
	}

	@include media-breakpoint-up(lg){
		display:flex;
		flex-wrap:wrap;
		align-items:center;
	}

	.card-setores-internas__figure{
		min-height:142px;
		min-width:142px;
		max-width:142px;
		margin-right:auto;
		margin-left:auto;
		display:flex;
		flex-wrap:wrap;
		align-items:center;
		justify-content:center;
		box-shadow:0 0 4px rgba(#000,0.4);
		transition:transform 0.6s linear;

		@include media-breakpoint-up(md){
			margin-bottom:0;
		}

	}

	.card-setores-internas__content{
		font-family: 'montserratbold';

		@include media-breakpoint-up(md){
			max-width:198px;
			width:100%;
			padding-left:12px;
		}

		.card-setores-internas__title{
			font-size:15px;
			color:$theme2;
		}

		.card-setores-internas__preco{
			font-size:25px;
			color:$theme;
		}

	}

	&:hover{

		.card-setores-internas__figure{
			transform:scale(1.15);
		}

	}

}