.header-section{

	& > *{
		margin-bottom:0;
		line-height:1.2;
		color:#FFF;
	}

	.header-section__title{
		position:relative;
		font-size:17px;
		font-family: 'montserratmedium';
		margin-bottom:8px;

		&:after{
			content:'';
			display:block;
			font-size:7px;
			width:1em;
			height:1em;
			border-radius:100%;
			background-color:#FFF;
			margin-top:4px;
		}

	}

	.header-section__subtitle{
		font-family: 'montserratbold';
		font-size:20px;
		margin-bottom:16px;

		@include media-breakpoint-down(md){
			font-size:17px;
		}

	}

	&.header-section--blue{

		& > *{
			color:#293759;
		}

		.header-section__title{

			&:after{
				background-color:$theme;
			}

		}

	}

}

.text-center{

	.header-section{
		padding-bottom:34px;

		.header-section__title{
			

			&:after{
				margin-left:auto;
				margin-right:auto;
			}

		}

	}

}

.text-lg-right{

	.header-section{

		.header-section__title{

			&:after{
				
				@include media-breakpoint-up(lg){
					margin-left:auto;
				}

			}

		}

	}

}