.card-destaques{
	max-width:250px;
	margin-right:auto;
	margin-left:auto;
	text-align:center;
	padding:26px 10px 0 10px;
	position:relative;
	margin-bottom:22px;

	&:before{
		content:"";
		background-color:#FFF;
		display:block;
		position:absolute;
		width:100%;
		height:100%;
		top:0;
		left:0;
		max-height:318px;
		box-shadow:0 0 8px rgba(#000,0.4);
	}


	& > *{
		line-height:1;
		position:relative;
		z-index:20;
	}

	.card-destaques__title{
		font-size:18px;
		font-family: 'montserratbold';
		color:#2a3859;
		min-height: 36px;
		margin-bottom:8px;
	}

	.card-destaques__desc-absolute{
		font-size:14px;
	}

	.card-destaques__desc{
		font-size:13px;
		font-style:italic;
		line-height:1.2;
		margin-bottom:6px;
		max-height:30px;
		overflow:hidden;
	}

	.card-destaques__preco{
		font-size:14px;
		color:#2a3859;
		font-family: 'montserratbold';
		margin-bottom:4px;
	}

	.card-destaques__oferta{
		color:$theme;
		font-family: 'montserratbold';
		font-size:28px;
		margin-bottom:14px;
	}

	.card-destaques__absolute{
		// position:absolute;
		// top:calc(100% - 150px);
		// left:0;
		// opacity:0;
		// transition:opacity 0.6s, top 0.6s linear;
		// padding:30px 40px 0 40px;
		// width:100%;
		display:none !important;

		&:before{
			content:"";
			display:block;
			position:absolute;
			width:100%;
			top:0;
			left:0;
			height:4px;
			background-color:$theme;
		}

		.card-destaques__desc-absolute{
			margin-bottom:8px;
			color:#2a3859;
			font-size:14px;
			font-family: 'montserratmedium';
			max-height:56px;
			overflow:hidden;
		}

		.card-destaques__box-circle{
			font-size:12px;
			width:(48 / 12) * 1em;
			height:(48 / 12) * 1em;
			display:flex;
			flex-wrap:wrap;
			align-items:center;
			justify-content:center;
			text-align:center;
			border-radius:100%;
			margin-right:auto;
			margin-left:auto;
			background-color:$theme;
			color:#FFF;
		}

	}

	.card-destaques__header{
		opacity:1;
		transition:opacity 0.6s linear;
		min-height:116px;
	}

	&:hover{

		.card-destaques__header{
			// opacity:0;
		}

		.card-destaques__absolute{
			opacity:1;
			top:0;
		}

	}

}
