.btn{

	&.btn-pill{
		border-radius: 2rem;
	}

	&.btn--responsivo{
		box-shadow:none;
		border:none;

		@include media-breakpoint-up(lg){
			margin-left:-15px;
		}

		.bar{
			width:31px;
			height:7px;
			position:relative;
			background-color:$theme2;
			display:inline-block;
			position:relative;
			margin-right:9px;

			@include beforeAfter{
				content:'';
				display:block;
				width:100%;
				height:100%;
				position:absolute;
				left:0;
				background-color:inherit;
			}

			&,
			&:before,
			&:after{
				transition:all 0.6s linear;
			}

			&:before{
				top:calc(100% + 4px);
			}

			&:after{
				bottom:calc(100% + 4px);
			}

		}

		.btn-responsivo__text{
			font-size:23px;
			color:$theme2;
		}

	}

	&.btn--theme{
		@include button-variant($theme,$theme);
		color:#FFF;
	}

	&.btn--theme-2{
		@include button-variant($theme2,$theme2);
		color:#FFF;
	}

	&.btn--white{
		@include button-variant(#FFF,#FFF);
		color:$theme2;
	}

	&.btn--padrao{
		font-size:13px;
		font-family: 'montserratextrabold';
		border-radius:60px;
		padding:20px 25px;
	}

	&.btn--padrao-2{
		font-size:13px;
		font-family: 'montserratextrabold';
		border-radius:60px;
		padding:8px 12px;
	}

	&.btn--padrao-3{
		font-size:12px;
		font-family: 'montserratmedium';
		border-radius:4px;
		padding:3px 10px;
	}

	&.btn--min-width-105{
		min-width:105px;
	}

	&.btn--min-width-130{
		min-width:130px;
	}

	&.btn--min-width-200{
		min-width:200px;
	}

}