.menu-topo{
	list-style-type:none;
	padding-left:0;
	margin-bottom:0;
	color:$theme2;
	display:flex;
	flex-wrap:wrap;
	align-items:center;
	justify-content:space-between;

	@include media-breakpoint-down(md){
		flex-direction:column;
	}

	& > li{
		flex-grow:1;
		position:relative;

		@include media-breakpoint-down(md){
			order:2;
			width:100%;
		}

		&.active{

			& > a{

				color:#FFF;
				background-color:$theme2;

				icon-awesome{

					.icon-awesome{
						background-color:#FFF;
					}

				}

			}

		}

		& > a{
			padding:11px 0px;
			display:block;
			text-align:center;
			font-size:13px;
			font-family: 'montserratbold';
			margin-top: 10px;
			border-radius: 15px 15px 0px 0px;
			color: #fff;

			@include media-breakpoint-down(md){
				padding:12px 0px;
				border-radius: 0px;
			}

			icon-awesome{
				display:block;
				position:relative;
				margin-left:auto;
				margin-right:auto;
				width:33px;
				height:33px;

				.icon-awesome{
					position:absolute;
					top:0;
					left:0;
					width:100%;
					height:100%;
					background-color:#fff;
					transition:background-color 0.6s linear;
				}

			}
		}

		a{

			.icon{
				display:block;
				margin-bottom:8px;
			}

			&:hover,
			&:focus{
				color:#FFF;
				background-color:$theme2;

				icon-awesome{

					.icon-awesome{
						background-color:#FFF;
					}

				}

			}

			&:hover{

				& ~ .collapse{

					@include media-breakpoint-up(lg){
						display:block !important;
					}

				}

			}

		}

		.collapse{
			z-index:600;
			list-style-type:none !important;
			background-color:$theme;
			padding-left:0;
			margin-bottom:0;
			font-size:14px;
			font-family: 'montserratbold';
			min-width:202px;
			color:#242530;
			background-color:#ededed;
			text-align:left;
			display:block;
			position:relative;

			@include media-breakpoint-up(lg){
				display:none;
				position:absolute;
				top:100%;
				left:0;
			}

			&:before{
				content:'';
				display:block;
				border:7px solid transparent;
				border-bottom-color:#ededed;
				position:absolute;
				left:30px;
				bottom:100%;

				@include media-breakpoint-down(md){
					left:50%;
					transform:translateX(-50%);
				}

			}

			li{

				a{
					display:block;
					padding:9px 20px;

					@include media-breakpoint-down(md){
						padding:14px 20px;
					}

				}

			}

			&:hover{
				display:block;
			}

		}

		&.li-logo{
			flex-grow:2;
			margin-bottom:0;

			@include media-breakpoint-down(md){
				order:1;
				padding:15px 15px;
				margin-bottom:15px;
			}

			@include media-breakpoint-up(lg){
				padding-left:35px;
				padding-right:0px;
			}

			a{
				padding:0;
			}

			&:hover{

				a{
					background-color:transparent;
				}

			}

		}

	}

}
