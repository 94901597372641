.form-internas{

	form{

		.form-group{
			margin-bottom:6px;

			.form-control{
				min-height:45px;
				background-color:#ededed;
				border-radius:0;
				padding-left:22px;
				font-family: 'montserratmedium';
				border-color:#ededed;
				font-size:14px;
				// @include place(#626262);
				@include place(lighten(#626262,10%));
				@include placeholdIt{
					overflow:visible;
				}
				resize:none;
				@include sombra(#ededed);

				

			}

			textarea.form-control{
				padding-top:10px;

				&[rows="6"]{
					min-height:148px;
				}

			}

		}

	}

}

.form-group-input-file{

	.form-control{
		display:flex;
		justify-content:space-between;
		align-items:center;
		flex-wrap:wrap;
	}

	input[type="file"]{
		position:fixed;
		left:-200vw;
		top:0;
	}

}

.form-buscas-topo{
	display:flex;
	flex-wrap:wrap;

	@include media-breakpoint-up(lg){
		padding-left:11px;
		padding-right:11px;
	}

	form{

		.input-group,
		.form-group{
			max-width:318px;
			margin-left:auto;
			margin-right:auto;
			margin-bottom:0;

			@include media-breakpoint-up(lg){
				min-width:318px;
			}

			.form-control{
				min-height:29px;
				padding:2px 10px;
				border-radius:4px 0 0 4px;
				font-size:12px;
				background-color:#FFF;
				border-color:#FFF;
				@include sombra(#FFF);
				@include place(#bebebe);
				font-family: 'montserratregular';
				border-right:none;
				height:auto;
			}

			.input-group-prepend{
				background-color:#FFF;
				margin:0;
				min-width:32px;
				display:flex;
				flex-wrap:wrap;
				align-items:center;
				justify-content:center;
				border-radius:0 4px 4px 0;

				.btn{
					padding:0;
					border:none;
					box-shadow:none;
					line-height:1;
				}

			}

		}

	}

}

.newsletter-form{

	@include media-breakpoint-down(md){
		text-align:center;
		padding:0 15px;
	}

	form{

		.col-lg-auto{

			@include media-breakpoint-down(md){
				margin-bottom:15px;
			}

		}

		.d-flex{

			@include media-breakpoint-down(md){
				justify-content:center;
			}

		}

		.fa{
			font-size:15px;
			transition:opacity 0.6s linear;

			&:hover{
				opacity:0.5;
			}

		}

		.input-group,
		.form-group{
			margin-bottom:0;

			.form-control{
				@include place(#bcbcbc);
				border-color:#FFF;
				@include sombra(#FFF);
				font-size:12px;
				border-radius:4px;
				font-family: 'montserratmedium';
				height:36px;

				@include media-breakpoint-up(lg){
					min-width:279px;
					height:26px;
				}

			}

		}

	}

}