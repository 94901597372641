.media-custom{
	margin-bottom:30px;
	text-align:center;

	@include media-breakpoint-up(md){
		display:flex;
		flex-wrap:wrap;
		margin-bottom:0;
		text-align:left;
	}

	.media-custom__img{
		margin-bottom:6px;

		@include media-breakpoint-up(md){
			max-width:81px;
			margin-bottom:0;
		}

	}

	.media-custom__content{
		color:#525252;
		
		@include media-breakpoint-up(md){
			max-width:256px;
			padding-left:12px;
		}

		.media-custom__title{
			font-size:15px;
			line-height:1.1;
			margin-bottom:4px;
			font-family: 'montserratextrabold';
		}

	}

}