.perguntas-frequentes{
	margin-bottom:6px;

	& > .btn{
		font-size:14px;
		font-family: 'montserratbold';
		color:#9e9e9e;
		padding:0;
		border:none;
		box-shadow:none;

		&:before{
			content:'';
			display:inline-block;
			color:inherit;
			background-color:currentColor;
			font-size:9px;
			width:1em;
			height:1em;
			border-radius:100%;
			margin-right:7px;
		}

		&[aria-expanded="true"]{
			color:$theme2;
		}

	}

	ul{
		list-style-type:none;
		padding-left:0;
		margin-bottom:0;
		padding-top:7px;
	}

}