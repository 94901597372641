.rodape{
	padding-top:48px;
	background-color:#00035C;
	color:#FFF;
	font-size:14px;

	.redes-sociais{
		.icone__nav{
			color: #00035C;

			&:hover{
				background-color: #1B218C;
				color: #fff;
			}
		}
	}

	@include media-breakpoint-down(md){
		text-align:center;
	}

	.rodape__logo{
		margin-bottom:18px;
	}

	.rodape__title{
		font-size:15px;
		font-family: 'montserratbold';
		margin-bottom:14px;
	}

	.rodape__menu{
		list-style-type:none;
		padding-left:0;
		margin-bottom:0;
		font-size:14px;

		@include media-breakpoint-up(lg){
			display:flex;
			flex-wrap:wrap;
		}

		li{
			margin-bottom:15px;

			&.active{

				a{
					font-family: 'montserratbold';
				}

			}

			@include media-breakpoint-up(lg){
				width:50%;
			}

		}

	}

	.row-principal{
		border-bottom:2px solid #00023B;
	}

	.rodape-copyright{
		text-align:center;
		padding-top:30px;
		padding-bottom:20px;
		font-size:10px;

		img{
			max-width:44px;
			margin-left:10px;
		}

	}

}
